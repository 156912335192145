import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FooterComponent} from './footer.component';
const routes: Routes = [
  {path:'',component:FooterComponent}
];

@NgModule({
  
})
export class FooterRoutingModule { }
