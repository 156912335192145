import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{CommonModule } from '@angular/common'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
 import{LayoutScreenModule} from './layout/layout.module';
import { RegistrationComponent } from './registration/registration.component';
import { CommonService } from './common.service';
import { HttpModule } from '@angular/http';
import {HttpClient, HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DonateDetailsComponent } from './donate-details/donate-details.component';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, SocialLoginModule  } from 'angularx-social-login';
import { HeaderScreenModule } from './common/header/header.module'
import { FooterScreenModule } from './common/footer/footer.module';
import { TopCausesComponent } from './top-causes/top-causes.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { environment } from '../environments/environment';
import { BrowsePledgeComponent } from './browse-pledge/browse-pledge.component';
import {jquery}from 'jquery/dist/jquery.min.js';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditorComponent } from './editor/editor.component';
//import { PaymentComponent } from './payment/payment.component'
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import {UnsubscribeModule }from './unsubscribe/unsubscribe.module';
import { EditPledgeComponent } from './edit-pledge/edit-pledge.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('357234987779-etmacn1qbspg26is0h5ke1khhl1ija3k.apps.googleusercontent.com')
  },
  {
     id: FacebookLoginProvider.PROVIDER_ID,
   provider: new FacebookLoginProvider('1152266235165102')
 },
]);
  export function provideConfig() {
    return config;
  }

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
   // ThankyouComponent,
    //EditPledgeComponent,

   // UnsubscribeComponent,
    
   // PaymentComponent,
   // MyProfileComponent
   // BrowsePledgeComponent
   // VerifyEmailComponent
    //TopCausesComponent
   // FooterComponent
    //HeaderComponent,
    //DonateDetailsComponent,
    
  ],
  imports: [
    CommonModule,
    FooterScreenModule,
    HeaderScreenModule,
    AppRoutingModule,
    LayoutScreenModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    BrowserModule.withServerTransition({appId: 'blogist'}),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    SocialLoginModule,
    // BsDropdownModule.forRoot(),
    // TimepickerModule.forRoot(),
    // DatetimePopupModule.forRoot(),
     HttpClientModule,       // (Required) For share counts
    HttpClientJsonpModule,  // (Optional) Add if you want tumblr share counts
    ToastrModule.forRoot( {
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }), FontAwesomeModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    CommonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
