import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {LayoutComponent} from './layout.component'
import { LayoutRoutingModule } from './layout-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
//import { ToastrModule } from 'ng6-toastr-notifications';
import { Routes, RouterModule } from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
//import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
//import {TranslateHttpLoader} from '@ngx-translate/http-loader';


const routes: Routes = [
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {
    path: '', component: LayoutComponent,
    children: [
       // { path: 'Home', loadChildren: '../home/home.module#HomeScreenModule' }
    ]
  }
];

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,FormsModule, [RouterModule.forChild(routes)],
    LayoutRoutingModule,ReactiveFormsModule,
    
  ]
})
export class LayoutScreenModule { }
