import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debug } from 'util';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../../_helpers/must-match.validator';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { $ } from 'protractor';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /**
   * Infinite scroll
   */

  user: SocialUser;
  fbuser: SocialUser
  show = false;
  array: any;
  compains: any
  scrollindex: number = 1;
  captcha: boolean = false;

  clicker: number = -1;
  SignupconfirmPassword: any;

  emailcode: string

  throttle = 100;
  scrollDistance = 2;
  scrollUpDistance = 1;
  direction = '';

  SignInPassword: string;
  SignInEmail: string;

  emialsucess: string = 'none'
  resetpassswd: string = 'none'

  signin: string = 'block';
  signOut: string = 'none';

  SignupPassword: string;
  SignupEmail: string;
  SignupMobile: string;
  SignupName: string;
  @ViewChild('paswrd', { static: true }) paswrd: ElementRef
  @ViewChild('signuppaswrd', { static: true }) signuppaswrd: ElementRef
  @ViewChild('rsetpaswrd', { static: true }) resetpaswrd: ElementRef
  @ViewChild('signupcnfrmpaswrd', { static: true }) signupcnfrmpaswrd: ElementRef

  display = 'none';
  Registerdesiplay = 'none';
  frgtpasdisplay = 'none';

  signincaptcha: string;


  resetpasswrd: string;
  resetcnfrmpasswrd: string;

  //reset password
  resetpaswrdsubmitted = false;
  resetpasswform: FormGroup

  //login
  Loginfrom: FormGroup;
  loginsubmitted = false;
  //register
  registerForm: FormGroup;
  registersubmitted = false;

  //forgot
  forgotForm: FormGroup;
  forgotsubmitted = false;

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  forgotemail: string;

  signstatus: string = "Sign In"
  username: string = '';
  dtemail: string = '';
  dtmobileno: string = '';

  Id: number

  Pager:string='block'


  constructor(private approute: ActivatedRoute, private toast: ToastrService, private spinner: NgxSpinnerService, private service: CommonService, private route: Router, config: NgbCarouselConfig, private formBuilder: FormBuilder, private authService: AuthService) {
    config.interval = 2000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

    this.Loginfrom = this.formBuilder.group({
      Lemail: ['', [Validators.required, Validators.email, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]],
      Lpass: ['', Validators.required],
    });

    this.registerForm = this.formBuilder.group({
      rname: ['', Validators.required],
      rlastname: ['', Validators.required],
      rmobile: ['', Validators.required],
      remail: ['', [Validators.required, Validators.email,]],
      rpassword: ['', Validators.required],
      rcnfrmpassword: ['', Validators.required],
    },
      {
        validator: MustMatch('rpassword', 'rcnfrmpassword')
      });

    this.forgotForm = this.formBuilder.group({
      //femail: ['', Validators.required],
      femail: ['', [Validators.required, Validators.email, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]],

    });

    this.resetpasswform = this.formBuilder.group({
      rtpasswrd: ['', Validators.required],
      rscnfrmpaswrd: ['', Validators.required],
    },
      {
        validator: MustMatch('rtpasswrd', 'rscnfrmpaswrd')
      });
  }

  get f() { return this.Loginfrom.controls; }
  get r() { return this.registerForm.controls; }
  get re() { return this.forgotForm.controls; }
  get rst() { return this.resetpasswform.controls; }
  ngAfterViewInit(): void {
    this.spinnerload();
  }
  spinnerload() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
  ngOnInit() {

    window.scroll(0, 0);

    this.Id = +localStorage.getItem('LoginID')
    
    if (this.Id <= 0) {
      this.signOut = 'none'
      this.signin = 'block'
      //console.log(this.username)
    }
    else {

      this.signstatus = "SIGN OUT";
      this.signOut = 'block'
      this.signin = 'none'
      this.username = localStorage.getItem('LoginName');
    }
  
    //this.compaigns()
    //this.bindobjectsforscroll(1);
    //window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  public resolved(captchaResponse: string) {
    ;
    if (captchaResponse != null) {
      console.log(`Resolved captcha with response: ${captchaResponse}`);
      this.captcha = true
    }
  }
  
  logout() {
    ;
    localStorage.setItem('LoginID', '0');
    localStorage.setItem('LoginName', null);
    localStorage.setItem('Email', null);
    localStorage.setItem('MobileNo', null);
    this.signin = 'block';
    this.signOut = 'none'
    this.route.navigateByUrl("/Home");
  }
  ShowPassword() {
    ;
    var data = this.paswrd
    if (this.paswrd.nativeElement.type == "password") {
      this.paswrd.nativeElement.type = "text";
      var eye = document.getElementById("spneye");
      eye.classList.remove("fa-eye-slash")
      eye.classList.add("fa-eye");
    }
    else {
      this.paswrd.nativeElement.type = "password";
      var eye = document.getElementById("spneye");
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash")


    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  ShowsignupPassword() {
    ;
    var data = this.signuppaswrd
    if (this.signuppaswrd.nativeElement.type == "password") {
      this.signuppaswrd.nativeElement.type = "text";
      var eye = document.getElementById("spneye");
      eye.classList.remove("fa-eye-slash")
      eye.classList.add("fa-eye");
    }
    else {
      this.signuppaswrd.nativeElement.type = "password";
      var eye = document.getElementById("spneye");
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash")


    }
  }
  CheckLogin(id: any) {
    // if (localStorage.getItem("LoginID") === null) {
    //   this.clicker = 1;
    //   this.openModalDialog();

    // }
    // else {
    this.route.navigateByUrl('/DonateDetails?id=' + id);
    // }
  }

  Signuplastname: any;
  Register() {
    
    this.registersubmitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    var data = {
      FirstName: this.SignupName,
      LastName: this.Signuplastname,
      EmailAddress: this.SignupEmail,
      MobileNumber: this.SignupMobile,
      Password: this.SignupPassword,
      RandomNumber: Math.floor(100000 + Math.random() * 900000) + 1,
      ReturnLink: "http://offsetnowapi.fadelsoft.com/#/Home?VerifyId="

    }

    this.service.SignUp(data).subscribe((data: any) => {
      ;
      console.log(data)
      if (data.Status == "200") {
        this.toast.success(data.Message);

        this.closeregistermodel();
        this.registerclear();
      }

      else {
        this.toast.warning(data.Message);

      }

    })

  }

  registerclear() {
    this.SignupName = '';
    this.SignupEmail = '';
    this.SignupMobile = '';
    this.SignupPassword = ''
    this.registersubmitted = false;

  }

  Recovery() {
    this.forgotsubmitted = true;
    if (this.forgotForm.invalid) {
      return;
    }

    
    var EmailAddress = this.forgotemail;
    //RandomNumber: Math.floor(100000 + Math.random() * 900000) + 1,
    var ReturnLink = "http://offsetnowapi.eisappserver.net/#/Home?forgotId="



    this.service.forgotmail(EmailAddress, ReturnLink).subscribe((data: any) => {
      ;
      console.log(data)
      if (data.Message == 'Success') {

        this.frgtpasdisplay = 'none';
        this.display = 'none'
        this.toast.success('recovery link send to your mail');
      }
      else {
        this.toast.warning(data.Message);
      }
    })
  }


  openModalDialog(val) {
    
    if (val == 'back') {
      this.frgtpasdisplay = 'none';
      this.Registerdesiplay = 'none';
      this.display = 'block';
    }
    else if (val == 'pledge') {
      var id = localStorage.getItem('LoginID');
      if (id == 'null') {
        this.display = 'block';
        this.clicker = 0;
      }
      else {
        this.route.navigateByUrl('/Fundraiser');
      }
    }
    else {
      this.clicker = 1;
      this.display = 'block';
      document.getElementById('modelsignin').style.display = 'block'

    } //Set block css
  }
  openBrowsePledgePage() {
    this.route.navigateByUrl("/BrowsePledge");
  }
  openAboutPage() {
    this.route.navigateByUrl("/About");

  }
  openTopCausesPage() {
    this.route.navigateByUrl("/TopCauses");
  }
  openContactPage() {
    this.route.navigateByUrl("/Contact");
  }
  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
    document.getElementById('modelsignin').style.display = 'none'
  }

  closeregistermodel() {
    this.Registerdesiplay = 'none'
  }
  showregistermodel() {
    this.display = 'none';
    this.frgtpasdisplay = 'none'
    this.Registerdesiplay = 'block'
  }


  closefrgtpsmodel() {
    this.frgtpasdisplay = 'none'
  }

  showfrgtpasmodel() {
    this.frgtpasdisplay = 'block';
    this.display = 'none'
  }

  navigateTofundraiser() {

    
    console.log(this.signincaptcha)
    this.loginsubmitted = true;
    if (this.Loginfrom.invalid) {
      return;
    }


    if (this.captcha == true) {
      this.service.Login(this.SignInEmail, this.SignInPassword).subscribe((data: any) => {
        ;
        console.log(data)
        if (data.Message == 'Success') {
          window.location.reload();
          localStorage.setItem('LoginID', data.objresult.LoginId);
          localStorage.setItem('LoginName', data.objresult.Name);
          localStorage.setItem('Email', data.objresult.EmailAddress);
          localStorage.setItem('MobileNo', data.objresult.MobileNo);
          this.username = localStorage.getItem('LoginName');
          this.dtmobileno = localStorage.getItem('MobileNo');
          this.dtemail = localStorage.getItem('Email');
          console.log(this.username);
          // this.toast.success('Successfully Login');
          if (this.clicker === 1) {
            this.closeModalDialog();
            //this.route.navigateByUrl('/Home');
            this.signOut = 'block';
            this.signin = 'none';
          }
          else if (this.clicker == 0) {
            this.closeModalDialog();
            this.signOut = 'block';
            this.signin = 'none';
            this.route.navigateByUrl('/Fundraiser');
          }
          else {
            document.getElementById('modelsignin').style.display = 'none';
            document.getElementById('modaldonate').style.display = 'block';
            //document.getElementById('dntname').innerHTML=this.username;
            // document.getElementById('dntemail').innerHTML=this.dtemail;
            // document.getElementById('dntmobileno').innerHTML=this.dtmobileno;
            this.signOut = 'block';
            this.signin = 'none';
            // document.getElementById('modaldonate').style.display='block';
            // document.getElementById('dntname').setAttribute('value',localStorage.getItem('LoginName'));
            // document.getElementById('dntemail').setAttribute('value',localStorage.getItem('Email'));
            // document.getElementById('dntmobileno').setAttribute('value',localStorage.getItem('MobileNo'));
          }

        }
        else {
          this.toast.error(data.Message);
        }
      })
    }
    else {
      this.toast.warning(' Select Captcha ')
    }



  }


  // togglePaused() {
  //   if (this.paused) {
  //     this.carousel.cycle();
  //   } else {
  //     this.carousel.pause();
  //   }
  //   this.paused = !this.paused;
  // }

  // onSlide(slideEvent: NgbSlideEvent) {
  //   if (this.unpauseOnArrow && slideEvent.paused &&
  //     (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
  //     this.togglePaused();
  //   }
  //   if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
  //    this.togglePaused();
  //   }
  // }

 


  myFunction() {

    var x = document.getElementById("myTopnav");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
  myFunction1() {
    var x = document.getElementById("uldetails");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  openbox() {

    var x = document.getElementById("dvmenu").ontoggle;

  }






  ValidateEmail() {
    
    var datanrveId = this.approute.snapshot.queryParamMap.get('VerifyId')
    console.log(datanrveId)
      ; if (datanrveId != null) {
        //this.emialsucess = 'block';
        this.service.EmailVerification(datanrveId).subscribe((data: any) => {
          console.log(data)
          if (data.Message == 'Your mail activation done') {
            this.emialsucess = 'block';
            this.toast.success("Email Verified SuceesFully !! Login")
          }
        })

      }

  }

  validateforgotemail() {
    var Id = this.approute.snapshot.queryParamMap.get('forgotId')
    if (Id != null) {
      this.resetpassswd = 'block';
      this.emailcode = Id
    }
  }

  resetpasswdclose() {
    
    this.resetpassswd = 'none'
    document.getElementById('modelresetpassword').style.display = 'none'
  }

  emialsucessclose() {
    this.emialsucess = 'none'
  }


  rersetPassword() {
    
    this.resetpaswrdsubmitted = true;
    if (this.resetpasswform.invalid) {
      return;
    }
    var data = {
      emailcode: localStorage.getItem('forgotid'),
      password: this.resetpasswrd
    }
    this.service.changePassword(data).subscribe((data: any) => {
      console.log(data)
      if (data.Message == 'Success') {
        this.resetpassswd = 'none';
        this.resetpasswdclose();
        this.toast.success("Password Updated Succesfully")
        this.route.navigateByUrl('/Home');
      }
      else {
        this.toast.warning('Contact Admin')
      }
    })

  }


  ShowresetPassword() {
    ;
    //var data = this.paswrd
    if (this.resetpaswrd.nativeElement.type == "password") {
      this.resetpaswrd.nativeElement.type = "text";
      var eye = document.getElementById("rstspneye");
      eye.classList.remove("fa-eye-slash")
      eye.classList.add("fa-eye");
    }
    else {
      this.resetpaswrd.nativeElement.type = "password";
      var eye = document.getElementById("rstspneye");
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash")


    }
  }


  Showconfirmpswrd() {
    ;
    //var data = this.paswrd
    if (this.signupcnfrmpaswrd.nativeElement.type == "password") {
      this.signupcnfrmpaswrd.nativeElement.type = "text";
      var eye = document.getElementById("cnfrmspneye");
      eye.classList.remove("fa-eye-slash")
      eye.classList.add("fa-eye");
    }
    else {
      this.signupcnfrmpaswrd.nativeElement.type = "password";
      var eye = document.getElementById("cnfrmspneye");
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash")


    }
  }

  signInWithGoogle(): void {
    

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      
      this.user = data;
      console.log(this.user)
      // console.log(this.user[0][SocialUser])

      this.emailAndFbAuth(this.user.email, this.user.name);
    })



  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
      ;
      this.fbuser = data;
      this.emailAndFbAuth(this.fbuser.email, this.fbuser.name);

    });
  }

  emailAndFbAuth(emial: string, name: string) {
    this.service.emailFbAuth(emial, name).subscribe((data: any) => {
      ;
      console.log(data)
      if (data.Status == '200') {

        localStorage.setItem('LoginID', data.objresult.LoginId);
        localStorage.setItem('LoginName', data.objresult.Name);
        localStorage.setItem('MobileNo', '');
        localStorage.setItem('Email', '');

        this.username = localStorage.getItem('LoginName');
        this.username = localStorage.getItem('LoginName');
        this.dtmobileno = localStorage.getItem('MobileNo');
        this.dtemail = localStorage.getItem('Email');
        console.log(this.username);
        // this.toast.success('Successfully Login');
        window.location.reload();
        //this.route.navigateByUrl('/Fundraiser');
      }
      else {
        this.toast.error(data.Message);
      }
    })
  }

}
