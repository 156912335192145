import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component'
import { FooterRoutingModule } from './footer.routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';
import { RecaptchaModule } from 'ng-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider,   } from 'angularx-social-login';

const routes: Routes = [
  {path: '', redirectTo: '/Home', pathMatch: 'full'},
  {
    path: '',
    component: FooterComponent
  }
];

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('528961187921-ld24b25466u4t2lacn9r35asg000lfis.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
  provider: new FacebookLoginProvider('1152266235165102')
},
]);
  export function provideConfig() {
    return config;
  }
  

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,FormsModule, [RouterModule.forChild(routes)],
    FooterRoutingModule,ReactiveFormsModule,NgbModule,OwlModule,RecaptchaModule,InfiniteScrollModule,
    ToastrModule.forRoot( {
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    })
    
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap:    [ FooterComponent ],
  exports:[FooterComponent]
})
export class FooterScreenModule { }
