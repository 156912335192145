import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CATCH_ERROR_VAR } from '@angular/compiler/src/output/output_ast';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = "http://offsetnowapi.eisappserver.net/api/";
    //this.baseUrl="http://offsetnowapi.eisappserver.net/api/";
    // this.baseUrl = "http://localhost:50853/api/";

  }

  public getHashtags() {
    return this.http.get(this.baseUrl + "admin/GetHashTags")
  }


  public getcategory() {
    return this.http.get(this.baseUrl + "admin/GetCategories")

  }
  public getfrequency() {
    return this.http.get(this.baseUrl + "admin/GetFrequencies")

  }
  public getfundraiserfor() {
    return this.http.get(this.baseUrl + "admin/GetDonationFor")

  }
  public getsubcategory(Id) {
    return this.http.get(this.baseUrl + "admin/GetSubCategories?categoryId=" + Id)
  }


  public getCampaignsById(Id) {
    return this.http.get(this.baseUrl + "admin/GetCampaignsByCategory?CategoryId=" + Id)
  }


  public Login(email, password) {
    return this.http.get(this.baseUrl + "admin/SignIn?emailAddress=" + email + "&password=" + password)
  }

  public SignUp(data) {
    return this.http.post(this.baseUrl + "admin/SignUp", data)
  }

  public changePassword(data) {
    return this.http.post(this.baseUrl + "admin/VerifyForgetPassword", data)
  }

  public forgotmail(EmailAddress, ReturnLink) {
    return this.http.get(this.baseUrl + "admin/ForgetPassword?email=" + EmailAddress + "&returnlink=" + ReturnLink)
  }
  public SaveCampain(data) {

    // return this.http.post("http://localhost:50853/api/admin/SaveCampaign",data)
    return this.http.post(this.baseUrl + "admin/SaveCampaign", data)
  }

  public UpdateCampain(data) {

    // return this.http.post("http://localhost:50853/api/admin/SaveCampaign",data)
    return this.http.post(this.baseUrl + "admin/UpdateCampaign", data)
  }


  public getCampaigns(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetCampaigns?CategoryId=" + Id)

  }

  public getCampaignsbypageNO(Id,pageno) {
    return this.http.get(this.baseUrl + "admin/GetCampaignsByPageNo?CategoryId=" + Id + "&PageNo=" + pageno)
    

  }

  public gettopcausesCampaigns(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetCampaignsUrgentCauses?CategoryId=" + Id)

  }

  public getsearchdata(search) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/CampaignSearch?search=" + search)

  }

  public getdonatedetails(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetDetailsCampaigns?Id=" + Id)

  }



  public getpledges(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetMyPledges?UserId=" + Id)

  }

  public getdonations(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetMyDonations?UserId=" + Id)

  }

  public getprofilelisr(Id) {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetProfile?UserId=" + Id)

  }

  public getcountries() {
    // return this.http.get("http://localhost:50853/api/admin/GetCampaigns")
    return this.http.get(this.baseUrl + "admin/GetCountries")

  }

  public Savemyprofile(data) {
    return this.http.post(this.baseUrl + "admin/SaveProfile", data);
  }
  public Changepassword(data) {
    return this.http.post(this.baseUrl + "admin/ChangePassword", data);
  }

  public collectedamountdata(data) {
    return this.http.get(this.baseUrl + "admin/GetMyDonateAmount?CampaignId="+data);
  }

  public EmailVerification(Id) {
    return this.http.get(this.baseUrl + "admin/VerifyMail?EmailCode=" + Id)
  }

  //   public getCampaigns() {

  //     return this.http.get(this.baseUrl + "AddNewUser/EmpList?companyId=" + 1)
  // }

  public emailFbAuth(email, Name) {
    return this.http.get(this.baseUrl + "admin/EMailFbAuth?emailAddress=" + email + "&Name=" + Name)
  }
  public SaveDonatePay(data) {
    return this.http.post(this.baseUrl + "admin/SaveDonatePay", data);
  }


  public Savecomments(data) {
    return this.http.post(this.baseUrl + "admin/SaveCampaignComment", data);
  }

  public Getcomments(Id,userid) {
    return this.http.get(this.baseUrl + "admin/GetComments?CampaignId=" + Id + "&UserId=" + userid);
  }

  public GetTopdoners(Id) {
    return this.http.get(this.baseUrl + "admin/GetTopDonors?CampaignId=" + Id);
  }
  public GetRecent(Id) {
    
    return this.http.get(this.baseUrl + "admin/GetRecentDonors?CampaignId=" + Id);
  }

  public GetCampignbyTitle(title) {
    return this.http.get(this.baseUrl + "admin/GetcampaignId?Title=" + title );
  }
  public UnSubscribeUser(data) {
    return this.http.post(this.baseUrl + "admin/UnSubscribeUser",data);
  }
  public Updatecomments(data) {
    return this.http.post(this.baseUrl + "admin/UpdateComment", data);
  }

  public SaveTestimonial(data) {
    return this.http.post(this.baseUrl + "admin/SaveTestimonial", data);
  }

  public getTestimonial() {
    return this.http.get(this.baseUrl + "admin/GetTestimonials")

  }

  public getcurrentcountry(lat,lng) {
    return this.http.get("http://api.geonames.org/countryCodeJSON?formatted=true&lat=" +lat+"&lng= "+lng+"&username=Mahendar&style=full");

  }

  public Hidecomments(data) {
    return this.http.post(this.baseUrl + "admin/HideComment", data);
  }


  public SaveSubscribereemail(data) {
    return this.http.post(this.baseUrl + "admin/SaveSubscriber", data);
  }

  public SaveQuickPayments(data) {
    return this.http.post(this.baseUrl + "admin/SaveQuickDonatePay", data);
  }


  public SaveFeedbback(data) {

    // return this.http.post("http://localhost:50853/api/admin/SaveCampaign",data)
    return this.http.post(this.baseUrl + "admin/SaveContact", data)
  }
}
