import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CharityWorld';

  ngOnInit() {
    this.loadScript('../assets/js/jquery-plugin-collection.js');
    //this.loadScript('../assets/js/jquery.min.js');
    //this.loadScript('../assets/js/bootstrap.min.js');
   //this.loadScript('../assets/js/jquery-plugin-collection.js');
     //this.loadScript('../assets/js/script.js');
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  
}
}
