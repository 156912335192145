import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

const routes: Routes = [
  {path: '', redirectTo: '/About', pathMatch: 'full'},
  // {path:"login",component:LoginScreenComponent},
{ path: 'login', loadChildren: './login/login.module#LoginScreenModule' },
  {path: 'Home', loadChildren: './home/home.module#HomeScreenModule'},
  {path: 'Fundraiser', loadChildren: './fund-raiser/fund-raiser.module#FundRaiserModule'},
  {path: 'Pledge/:id', loadChildren: './donate-details/donate-details.module#DonateDetailsModule'},
  {path:'How-it-works',loadChildren:'./about/about.module#AboutScreenModule'},
  {path:'Contact',loadChildren:'./contact/contact.module#ContactScreenModule'},
  {path:'TopCauses',loadChildren:'./top-causes/top-causes.module#TopCausesScreenModule'},
  {path:'Email',loadChildren:'./verify-email/verify-email.module#VerifyEmailModule'},
  {path:'BrowsePledge',loadChildren:'./browse-pledge/browse-pledge.module#BrowsePledgeScreenModule'},
  {path:'MyProfile',loadChildren:'./my-profile/my-profile.module#MyprofileScreenModule'},
  {path:'Payment',loadChildren:'./payment/payment.module#PaymentModule'},
  {path:'Unsubscribe',loadChildren:'./unsubscribe/unsubscribe.module#UnsubscribeModule'},
  {path:'EditPledge',loadChildren:'./edit-pledge/edit-pledge.module#EditpledgeModule'},
  {path:'Thankyou',loadChildren:'./thankyou/thankyou.module#ThankyoutModule'}
  //{path:'Payment',loadChildren:'./payment/payment.module#PaymentModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true,enableTracing:true}),],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
